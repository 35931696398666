<template>
    <div id="wrapper">
        <!-- top -->
        <div class="tea_headers">
                    <div class="tea_header_ls">
                        <span>ICD创意价值设计 —— 学员端</span>
                    </div>
                    <div class="tea_header_rs clearfix">
                        <div class="u-avatar fr" @mouseover="mouseover()" @mouseout="mouseout()">
                            <div class="fl member">
                                <img class="avatar fl" :src="this.$store.state.user_avatar===null ||this.$store.state.user_avatar==''||this.$store.state.user_avatar=='null'?'https://static.ibeisai.com/image/default_avatar.png':this.$store.state.user_avatar" alt="">                    
                            </div>
                            <p class="name fl">
                                <span>{{this.$store.state.user_name===null||this.$store.state.user_name===''||this.$store.state.user_name==='null'?'':this.$store.state.user_name}}</span>
                            </p>
                            <ul class="dropdown-menu" v-show="login_acc==true">
                                <li @click="count()">账号设置</li>
                                <li @click="exits()">安全退出</li>
                            </ul>
                        </div>
                        <!-- <div class="tea_he_imgs fl">
                            <img :src="this.$store.state.user_avatar===null ||this.$store.state.user_avatar==''||this.$store.state.user_avatar=='null'?'https://static.ibeisai.com/image/default_avatar.png':this.$store.state.user_avatar" alt="">                    
                            <span>{{this.$store.state.user_name===null||this.$store.state.user_name===''||this.$store.state.user_name==='null'?'':this.$store.state.user_name}}</span>
                        </div> -->
                        <!-- <div class="tea_exits fl" @click="exits()">
                            <i class="el-icon-switch-button"></i>
                            <span>退出</span>
                        </div> -->
                    </div>
                </div>
        <!-- center -->
        <div class="center" v-if="this.$route.name=='StudentSetting'">
            <router-view></router-view>
        </div>
        <div class="center clearfix" v-else>
            <div class="center_left fl">
                <el-menu
                    :default-active="activePath"
                    :unique-opened='true'
                    class="el-menu-vertical-demo"
                    background-color="#fff"
                    text-color="#272727"
                    active-text-color="#ffd04b">
                        <router-link to="Myclass">
                            <el-menu-item index="Myclass">
                                <i class="el-icon-date" style="margin-right:10px;font-size:18px"></i>
                                <span slot="title">我的课堂</span>
                            </el-menu-item>
                        </router-link>
                </el-menu>
            </div>
            <div class="center_right fl">
                <router-view></router-view>
            </div>
        </div>
        <!-- footer -->
        <footer class="footer text-right">
            <span>© 2022. ICD创意价值设计_v3.2.0  All rights reserved.</span>
        </footer>
    </div>
</template>
<script>
export default {
    data () {
        return {
            login_acc:false,
            activePath:'',
            drawer:false,
            drawList:[]
        }
    },
    methods: {
        drawers(){
            this.axios.coursedoc({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    this.drawList = res.data.data
                    this.drawer = true
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        mouseover(){
            this.login_acc = true
        },
        mouseout(){
            this.login_acc = false
        },
        getPath(){
            let currentPath = this.$route
            this.activePath = currentPath.name
        },
        exit(){
            this.$router.push('/login')
            localStorage.clear()
        },
        count(){
            this.$router.push({name:'StudentSetting'})
        },
        //退出登录
        exits(){
            this.axios.userlogout({
                token:this.$store.state.token
            }).then(res=>{
                if(res.data.code==0){
                    this.$router.push('/login')
                    localStorage.clear()
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        }
    },
    watch:{
        $route(to,from){
            this.getPath()
        }
    },
    mounted() {
        this.getPath()
    }
}
</script>
<style scoped>
@import './../../assets/css/student/student.css';
</style>
<style>
.center_left .el-menu-item i{
    color: #272727;
}
.center_left .el-menu{
    border: none;
}
.center_left .el-menu-item, .el-submenu__title{
    height: 45px;
    line-height: 45px;
    margin: 5px 0;
    padding-left: 24px!important;
}
.center_left .is-active{
    background: #EBEFF2!important;
    border-left: 4px solid #3E67D9 !important;
    color: #3E67D9 !important;
}
.center_left .is-active i{
    color: #3E67D9 !important;
}
.center_left .el-menu-item.is-active{
    padding-left: 20px!important;
}
/* 资源库抽屉 */
.drawerBox .el-drawer__header{
    padding: 0 8px;
    height: 44px;
    line-height: 44px;
    background: #f1f6fe;
    color: #0e1726;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
}
</style>